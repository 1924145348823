import { LinearProgress } from '@material-ui/core';
import { useTranslation } from 'next-i18next';
import { useEffect, useState } from 'react';

import { useSlideIn } from '@/src/context/slide-in-context';
import SectionHeader from '@/src/widgets/section-header';

import { getTransactions, transactionHeaders } from '../../property/items/transactions/constants';
import IncompleteTransactionsBox from '../../transactions/items/incomplete-transactions-box';

export default function LatestTransactions() {
  const [transactions, setTransactions] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { t } = useTranslation('common');
  const { setSlideIn } = useSlideIn();

  useEffect(() => {
    getTransactions(setTransactions, setLoading, 'get-latest-transactions', null, setSlideIn, t);
  }, []);

  const headers = transactionHeaders?.filter(
    (col) => col.title !== 'general.boa' && col.title !== 'general.loa'
  );

  return (
    <div>
      <div className="pb-4">
        <IncompleteTransactionsBox />
      </div>
      <SectionHeader
        title={t('info.owner-table.latest-transactions')}
        sources={t('info.owner-table.source')}
        helpTextId={'registeredTransactions'}
      />

      <div className="relative max-h-[400px] overflow-y-auto 2xl:pr-4">
        <div className="sticky top-0 bg-white dark:bg-dark-primary z-50">
          <div className="items-start justify-start w-full hidden md:grid grid-cols-10 2xl:grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr_1.3fr_1fr_0.3fr_1.4fr] text-xs pt-2">
            {headers?.map((col, idx) => {
              return (
                <div key={idx} className={`col-span-${col.cols} px-2 font-demi`}>
                  {t(col.title)}
                </div>
              );
            })}
          </div>
        </div>

        {loading ? <LinearProgress className="w-full my-4" /> : <div className="w-full h-2" />}

        <div>
          {transactions && transactions?.length > 0 && !loading ? (
            <>
              <div className="w-full relative flex-col justify-center items-center overflow-hidden overflow-y-auto pb-8">
                {transactions?.map((trans, idx) => {
                  return (
                    <div
                      key={idx}
                      className="items-start justify-start w-full hover:bg-bobo-orangelight dark:hover:bg-gray-800 transition-colors duration-200 odd:bg-gray-50 dark:odd:bg-dark-lessfaded rounded-md md:grid grid-cols-10 2xl:grid-cols-[1fr_1fr_1fr_1fr_1fr_1fr_1.3fr_1fr_0.3fr_1.4fr] leading-3 xl:leading-5 text-mini xl:text-xs py-1 md:py-0">
                      {headers?.map((col, idx) => {
                        return (
                          <div
                            key={idx}
                            className={`col-span-${col.cols} p-2 flex md:flex-col justify-between items-start`}>
                            <div className="md:hidden">{t(col.title)}:</div>
                            <div className="w-full text-right md:text-left">{trans[col.data]}</div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>

              <div className="sticky bottom-0 bg-gradient-to-b from-transparent to-white dark:to-dark-primary h-8 xl:h-16 z-50 w-full" />
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}
