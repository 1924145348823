import { ExclamationCircleIcon } from '@heroicons/react/24/outline';
import { showArticle } from '@intercom/messenger-js-sdk';
import { Trans, useTranslation } from 'react-i18next';

export default function IncompleteTransactionsBox() {
  const { t } = useTranslation('common');

  const handleClick = (e) => {
    e.stopPropagation();
    showArticle('244033');
  };

  return (
    <div className="border border-gray-200 dark:border-dark-morefaded bg-bobo-proplight dark:bg-gray-800 rounded-md px-3 py-5 text-xs text-gray-800 dark:text-gray-300 flex items-center gap-2">
      <div className="w-1/8">
        <ExclamationCircleIcon className="text-gray-400 w-5 h-5" />
      </div>

      <div>
        <Trans i18nKey={'info.owner-table.incomplete-data-text'} t={t}>
          Notera att denna första version av transaktionsdata innehåller de allra flesta lagfarna
          transaktioner men saknar vissa transaktionstyper. Vi arbetar för att komplettera datan.
          Mer information kan du hitta{' '}
          <button
            onClick={handleClick}
            className="font-bold text-bobo-prop dark:text-dark-prop hover:text-bobo-prophover dark:hover:text-dark-prophover transition-colors duration-200">
            här
          </button>
          .
        </Trans>
      </div>
    </div>
  );
}
