/* External Libraries */
import { CRMActivityType } from 'shared/dist/types';

import TimelineItem from './timeline-item';

export default function ActivityHorizontalTimeline({
  data,
  loading
}: {
  data: any[];
  loading: boolean;
}) {
  if (data && data?.length < 1 && !loading) {
    return (
      <div className="w-full relative flex-col justify-center items-center">
        <div className="h-full absolute w-[1px] bg-bobo-proplight dark:bg-dark-morefaded left-[15px] z-10" />
        <TimelineItem index={100} item={{ category: CRMActivityType.noRecords }} />
      </div>
    );
  }

  return (
    <div className="relative h-full">
      <div className="h-full absolute w-[1px] bg-gray-100 dark:bg-dark-morefaded left-[23px] z-10" />

      <div className="w-full relative flex-col justify-center items-center max-h-[806px] overflow-auto 2xl:pr-4">
        {data &&
          data?.map((d, idx) => {
            return <TimelineItem index={idx} key={idx} item={d} />;
          })}
      </div>
    </div>
  );
}
